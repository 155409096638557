body {
  background-color: rgb(128, 215, 207);
  color: #fff1e6;
  font-size: 1rem;
}

.contact-section {
  display: grid;
  justify-items: center;
  grid-template-rows: 140px 450px 1fr;
}

.contact-section h1 {
  color: #516b7b;
  font-size: 2rem;
  text-shadow: 1px 1px 1px #414b52;
}

.contact-section button {
  font-size: inherit;
  font-weight: 700;
  background-color: #fff1e6;
  color: #516b7b;
  border: 1px solid #516b7b;
  border-radius: 30px;
  padding: 1em 5em;
  margin-top: 1em;
  align-self: center;
  text-decoration: none;
  cursor: pointer;
}

.contact-section .content-container a {
  color: #fff1e6;
  text-decoration: none;
}

.contact-section label {
  font-size: 0.9rem;
  margin: 1em auto;
  width: 100%;
  overflow: hidden;
}

.contact-section input,
.contact-section textarea {
  background-color: initial;
  border: none;
  border-bottom: 1px solid #516b7b;
  padding: 1em 2em;
  width: 100%;
}

.contact-section input::placeholder,
.contact-section textarea::placeholder {
  font: inherit;
  font-weight: 700;
  font-size: 1rem;
  color: #fff1e6;
}

.contact-section .contact {
  margin: 1em 0;
}

.contact-section .content-container h2, .contact-section .content-container a { 
  font-weight: 700;
  font-size: 1.2rem;
}

.contact-section .content-container p {
  font-size: 1rem;
  font-weight: 400;
}

.form-container,
.contact-section .content-container {
  width: 85%;
}

.contact-section .input-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.contact-section .header-container .logo {
  position: fixed;
  width: 15vh;
  height: 10vh;
  top: 20px;
  left: 10px;
  z-index: 10;
}

/* Media Queries */
@media (min-width: 375px) {
  .contact-section {
    grid-template-rows: 140px 390px 1fr;;
  }
}

@media (min-width: 425px) {
  .contact-section {
    grid-template-rows: 140px 315px 1fr;
  }
}

@media (min-width: 768px) {
  .contact-section h1 {
    font-size: 2.25rem;
  }

  .contact-section {
    grid-template-rows: 140px 340px 1fr;
  }

  .contact-section button {
    align-self: flex-start;
  }

  .contact-section .content-container, .form-container {
    width: 70%;
  }
}

@media (min-width: 1024px) {
  .contact-section {
    grid-template-rows: 140px 400px 1fr;
    justify-items: center;
    align-items: center;
  }

  .contact-section h1 {
    font-size: 3rem;
  }

  .contact-section .content-container {
    font-size: 1.6rem;
  }

  .form-container p {
    font-size: 1.5rem;
  }

  .contact-section .content-container, .form-container {
    width: 65%;
  }
}
