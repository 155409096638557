/* external css: flickity.css */

body { font-family: sans-serif; margin: 0;  }
.carousel {
  border: 1px solid;
  margin-bottom: 40px;
}

.logo {
  width: 20vh;
  height: 15vh;
  position: fixed;
  top: 30px;
  left: 30px;
  z-index: 10;
}

.carousel-a .cell span{
  z-index: 10; 
  position: absolute;
  top: 30vh;
  left:3vh;
  color: white;
  font-size: 35px;
}

.carousel-b .cell span{
  color: white;
  font-size: 12px;
  margin-left: 5px;
}

.carousel-a .cell .intro-project-a{
  font-size: 24px;
  color: rgb(255, 255, 255);
  padding: 250px;
  font-style: italic;
  font-weight: 500;
  font-family:'Times New Roman', Times, serif;
}

.flickity-prev-next-button.next, .flickity-button-icon{
  color: rgb(80, 178, 179);
}

.carousel-a{
  width: 100vw;
}


.carousel-a .cell .intro-project-b{
  font-size: 15px;
  color: rgb(255, 255, 255);
}

.cell {
  height: 78vh;
  width: 100vw;
  margin: 0 5px;
  background:rgb(80, 178, 179);
}


.cell.is-selected {
  outline: 10px solid hsla(0, 0%, 0%, 0.2);
  outline-offset: -10px;
}

.cell.is-nav-selected {
  outline: 10px solid hsla(0, 0%, 100%, 0.6);
  outline-offset: -10px;
}

.carousel-b .cell {
  width: 160px;
  height: 12vh;
  background-color: black;
}

.carousel-b img, .carousel-a img {
  width: 100%;
  height: 100%;
}

