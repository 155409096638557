.container {
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    display: block;
    overflow: hidden;
    height: -webkit-fill-available;
}
.container::-webkit-scrollbar {display: none;}

.container .scene {
    display: block;
    position: absolute;
    height: 100%;
    top: 0;
    transform-origin: left;
    transform: translateX(0px);
}

.container .scene:hover {cursor: grab;}
.container .scene:active {cursor: grabbing;}

.container .logo {
    width: 20vh;
    height: 17vh;
    position: fixed;
    top: 30px;
    left: 30px;
    z-index: 10;
}

.container .logo:hover {cursor: pointer;}

@media screen and (max-width: 1100px) {
    .container .logo {
        top: 10px;
        left: 10px;
    }
}