body {
    background-color: rgb(128, 215, 207);
}

.about-main {
    display: block;
    white-space: nowrap;
    height: 90vh;
    width: 85vw;
    overflow-y: auto;
    position: absolute;
    top: 5vh;
    left: 5vw;
}
.about-main::-webkit-scrollbar {height: 8px;}
.about-main::-webkit-scrollbar-track {background: rgba(0, 0, 0, 0);}
.about-main::-webkit-scrollbar-thumb {background: rgb(180, 180, 180); border-radius: 5px;}
.about-main::-webkit-scrollbar-thumb:hover {background: rgb(150, 150, 150);}

.about-main .about-block {
    width: fit-content;
   
    overflow: visible;
    display: block;
    padding: 15px;
    border-left: 1px solid black;
    border-right: 1px solid black;
    transition-duration: .3s;
    position: relative;
    overflow-y: auto;
    word-wrap: normal;
    white-space: normal;
}
.about-main .about-block::-webkit-scrollbar {width: 10px;}
.about-main .about-block::-webkit-scrollbar-track {background: rgba(0, 0, 0, 0);}
.about-main .about-block::-webkit-scrollbar-thumb {background: rgb(128, 215, 207); border-radius: 5px;}
.about-main .about-block::-webkit-scrollbar-thumb:hover {background: rgb(69, 154, 155);}
.about-main .about-block:hover {
    background-color: rgb(80, 178, 179);
}
.about-main .about-block:last-child {
    border-right: 1px solid black;
    
}

.about-main .about-company .main-img {
    width: 15vh;
    height: 9vh;
    position: sticky;
    padding-left: 0vh;
    padding-top: 0vh;
}

.about-main .about-company .tagline {
    margin-left: 5vh;
    display: inline-block;
    font-size: large;
    font-weight: bold;
}

.about-main .about-company .summary {
    display: block;
    margin-top: 20px;
}

.about-main .about-company .affiliation {
    height: 15vh;
    display: inline-block;
    margin-top: 15px;
    margin-right: 15px;
    margin-bottom: 15px;
}


@media only screen and (max-width: 1024px) {
    .about-main .people {
        height: 70%;
        width: fit-content;
        display: block;
        border-left: 1px solid black;
        border-right: 1px solid black;
        text-align: center;
    }

    .about-main .people-container {
        display: inline;
        height: 60%;
        width: fit-content;
       
    }
    .about-main .people span {
        margin-left: 20px;
        display: block;
        max-width: 80%;
        
    }
    
} 

@media only screen and (min-width: 1024px) {
    .about-main .people {
        height: 100%;
        display: inline-block;
        width: 50%;
        border-left: 1px solid black;
        border-right: 1px solid black;
        text-align: center;
        box-sizing: border-box;
    }
    .about-main .people:nth-child(even) {border-left: unset;}
    .about-main .people-container {
        display: block;
        height: 60%;
        width: 100%;
    }
    
    .about-main .people span {
        display: block;
        max-width: 38.5vw;
    }
} 




.about-main .people .photo {
    width: 12vh;
}


.about-main .people .name {
    font-size: large;
    font-weight: bold;
}