/* external css: flickity.css */

body { font-family: sans-serif; margin: 0;}

.logo {
  width: 20vh;
  height: 15vh;
  position: fixed;
  top: 30px;
  left: 30px;
  z-index: 10;
}

.anemoia_project .title{
  font-size: 3vw;
  color: white;
  text-shadow: 2px 2px 4px lightgray;
  position: fixed;
  top: 200px;
  left: 200px;
}

.anemoia_project img{
  width: 100%;
  height: 100%;
}

.anemoia_project .quote{
  font-size: 1.4vw;
  color: white;
  text-shadow: 2px 2px 4px lightgray;
  position: fixed;
  top: 280px;
  left: 200px;
  width:80vw;
}

.projects-back-button img {
  width:35px;
  height: auto;
  font-size: 1.4vw;
  color: white;
  text-shadow: 2px 2px 4px lightgray;
  position: fixed;
  top: 360px;
  left: 210px;
}

.projects-back-button {
  font-size: 15px;
  color: white;
  text-shadow: 2px 2px 4px lightgray;
  position: fixed;
  top: 366px;
  left: 260px;
  font-style: italic;
  font-weight: 700;
  text-decoration: none;

}
.projects-back-button:hover {cursor: pointer;}