.tour-project-title {
    position: fixed;
    top: 20px;
    left: 0;
    display: block;
    width: 100vw;
    text-align: center;
    height: fit-content;
    z-index: 5;
    font-size: 50px;
    color: white;
    text-shadow: 5px 5px 10px gray;
    font-weight: bold;
    text-decoration: none;
}

@media screen and (max-width: 1100px) {
    .tour-project-title {
        top: calc(15vh + 40px);
    }
}