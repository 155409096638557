.hamburger-btn {
    position: fixed;
    display: block;
    z-index: 10;
    width: 50px;
    height: 40px;
    border-radius: 5px;
    background-color: rgb(80, 178, 179);
    right: 30px;
    top: 30px;
}
.hamburger-btn:hover {cursor: pointer;}

.hamburger-btn .icon {
    display: block;
    width: 30px;
    height: 30px;
    margin: 5px 10px;
}

.menu-main {
    width: 25vw;
    height: 100vh;
    height: -webkit-fill-available;
    position: fixed;
    z-index: 11;
    display: block;
    background-color: rgb(80, 178, 179);
    top: 0;
    right: 0;
    color: white;
}
.menu-main .close {
    position: absolute;
    right: 10px;
    top: 10px;
    width: 30px;
    height: 30px;
}
.menu-main .menu-items::-webkit-scrollbar {width: 7px;}
.menu-main .menu-items::-webkit-scrollbar-track {opacity: 0;}
.menu-main .menu-items::-webkit-scrollbar-thumb {border-radius: 5px; background-color: rgb(200, 200, 200);}
.menu-main .menu-items::-webkit-scrollbar-thumb:hover {background-color: rgb(170, 170, 170);}
.menu-main .close:hover {cursor: pointer;}

.menu-main .menu-items {
    width: calc(100% - 40px);
    height: fit-content;
    position: relative;
    top: 70px;
    left: 2vw;
    max-height: calc(100% - 170px);
    overflow-y: auto;
}
.menu-main .menu-items .menu-item {
    display: block;
    font-size: 2.5vw;
    text-decoration: none;
    color: inherit;
    margin-bottom: 30px;
}
.menu-main .menu-items .menu-item:hover {
    cursor: pointer;
}

.menu-main .menu-items .menu-item .menu-item {
    margin: unset;
    margin-left: 2vw;
    margin-top: 10px;
    font-size: 2vw;
}

.menu-main .menu-items .expand {
    font-size: 1vw;
    transition-duration: .5s;
}
.menu-main .menu-items .expand-rotated,
.menu-main .menu-items .menu-item:hover .expand {
    transform: rotate(90deg);
}

.menu-main .media-menu {
    display: block;
    position: absolute;
    bottom: 15px;
    left: 2vw;
}

.menu-main .media-menu .menu-item {
    position: relative;
    width: 2.3vw;
    height: 2.3vw;
    margin: 0 1vw;
    display: inline-block;
    color: white;
    margin-bottom: 15px;
}

/* for mobile view */
@media screen and (max-width: 1100px) {
    .menu-main {
        width: 100%;
    }
    .menu-main .menu-items .menu-item {
        font-size: 5vh;
        margin-left: 5vw;
    }
    .menu-main .menu-items .expand {
        font-size: 2vh;
    }
    .menu-main .menu-items .menu-item .menu-item {
        margin-left: 6vw;
        font-size: 4vh;
    }
    .menu-main .media-menu .menu-item {
        width: 10vw;
        height: 10vw;
        margin: 0 4.5vw;
    }
}